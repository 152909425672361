import {
  Box,
  Grid,
  TextField,
  Button,
  styled,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import { navigate, PageProps } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { DatePicker, LocalizationProvider } from '@material-ui/lab'
import AdapterDateFns from '@material-ui/lab/AdapterDateFns'

import LocationPicker from '../../../components/location-picker'
import PageHeader from '../../../components/page-header'
import MobileNavbar from '../../../components/navbar/mobile'
import Navbar from '../../../components/navbar'
import { useCart } from '../../../hooks/useCart'
import { CartCoachDetails } from '../../../services/api/endpoints/carts'

const StyledContainer = styled(Box)`
  margin-top: 30px;
  padding: 1rem;
  padding-top: 0;
`

const StyledPrimaryButton = styled(Button)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(600)]: {
        marginTop: '20px',
        marginBottom: '60px',
        width: '100%',
      },
    }
  }}
  background-color: #008174;
  font-weight: bold;
  padding: 10px;
  width: 300px;
`

const RegisterCoachDetailsPage = (props: PageProps) => {
  const cartType = 'coach'
  const isMobile = useMediaQuery({ query: `(max-width: 945px)` })
  const [address, setAddress] = useState<any | undefined>(undefined)

  const { cart, setParticipantDetails } = useCart({
    type: cartType,
  })

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      gender: '',
      email: '',
      mobile: '',
      emergencyContact: '',
      emergencyContactNumber: '',
    },
  })

  const onSubmit: SubmitHandler<any> = async (createData: any) => {
    if (
      !createData.dateOfBirth ||
      createData.dateOfBirth.toString() === 'Invalid Date'
    ) {
      toast.error('Invalid Birth Date, Please use format of mm/dd/yy')
    } else {
      const participantDetails = cart?.data?.participantDetails
      await setParticipantDetails({
        participantDetails: {
          ...participantDetails,
          ...{ ...createData, address },
        },
        type: cartType,
      }).unwrap()
      navigate('/register/coach/headshot')
    }
  }

  useEffect(() => {
    if (cart?.data?.participantDetails) {
      const participantDetails: CartCoachDetails =
        cart?.data?.participantDetails

      reset({
        firstName: participantDetails.firstName,
        lastName: participantDetails.lastName,
        dateOfBirth: participantDetails.dateOfBirth,
        gender: participantDetails.gender,
        email: participantDetails.email,
        mobile: participantDetails.mobile,
        emergencyContact: participantDetails.emergencyContact,
        emergencyContactNumber: participantDetails.emergencyContactNumber,
      })
      setAddress(participantDetails.address)
    }
  }, [cart?.data?.participantDetails])

  return (
    <>
      {isMobile ? (
        <MobileNavbar bg="#008174" />
      ) : (
        <Navbar path={props.location.pathname} bg="#008174" />
      )}
      <StyledContainer>
        <Box
          sx={{
            marginTop: '20px',
          }}
        >
          <PageHeader title="Personal details" />
        </Box>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="First name"
                    variant="filled"
                    size="small"
                    fullWidth
                    {...field}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Last name"
                    variant="filled"
                    size="small"
                    fullWidth
                    {...field}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="dateOfBirth"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      {...field}
                      label="Date of Birth"
                      InputProps={{ disableUnderline: true, error: false }}
                      renderInput={(renderParams: any) => (
                        <TextField
                          InputProps={{ disableUnderline: true }}
                          inputProps={{ tabIndex: 3 }}
                          id="filled-basic"
                          variant="filled"
                          size="small"
                          {...renderParams}
                          fullWidth
                          required
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <FormControl variant="filled" size="small" fullWidth>
                    <InputLabel id="select-gender">Gender</InputLabel>
                    <Select
                      labelId="select-gender"
                      id="select-gender"
                      {...field}
                      required
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Email"
                    variant="filled"
                    size="small"
                    fullWidth
                    {...field}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="mobile"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Mobile"
                    variant="filled"
                    size="small"
                    fullWidth
                    {...field}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <LocationPicker setAddress={setAddress} defaultValue={address} />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="emergencyContact"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Emergency contact"
                    variant="filled"
                    size="small"
                    fullWidth
                    {...field}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="emergencyContactNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Emergency contact number"
                    variant="filled"
                    size="small"
                    fullWidth
                    {...field}
                    required
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: 'center',
                marginBottom: '60px',
                marginTop: '20px',
              }}
            >
              <StyledPrimaryButton type="submit" variant="contained" fullWidth>
                Next
                <ArrowForwardIcon
                  style={{
                    fontSize: '16px',
                    marginLeft: '10px',
                  }}
                />
              </StyledPrimaryButton>
            </Grid>
          </Grid>
        </Box>
      </StyledContainer>
    </>
  )
}

export default RegisterCoachDetailsPage
